@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'design-system-fonts.scss';

html, body { height: 100%; }

body {
  color: var(--text-color);
  margin:0;
  font-family: var(--body-font);
  background: var(--color-interface-background-secondary, #F5F6F6);
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-position:right bottom;
  @media only screen and (max-width: 720px) {
    background-size: 300px auto;
  }
}

.loading-wapple {
  background-repeat: repeat;
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
}

.loader-container{
  display: flex; 
  padding: 30px;
  justify-content: center; 
  align-items: center; 
  background: white;
}

.medium-text {
  font-family: var(--heading-font);
}

.smaller-text{
  font-size: 14px;
  margin: 0;
}

.hint {
  font-size: 13px;
  margin: 0;
}

.page-container{
  padding: 75px 10px 30px 10px;

  mat-tab-body{
    h1, h2, h3, h4, h5, h6{
      font-size: 14px !important;
      font-weight: normal !important;
    }
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.audit-tool-cards-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.questions-accordion {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 !important;
}

.store-name {
  font-family: var(--heading-font);
  font-size: 22px;
}

.hint-line-text{
  color: var(--primary-color) !important;
}

.audit-tool-card {
  margin: 10px;
  flex-grow: 1;
  max-width: 600px;
  min-width: 300px;
  text-align: center;
  border-radius: 12px 12px 0px 0px;
  border: 1px solid var(--color-interface-border-weak, #E1E3E4);
  background: var(--color-interface-background-primary, #FFF);
  padding: var(--space-content-lg) var(--space-content-lg);
}

.audit-tool-card-outer{
  box-shadow: 0px 0px 2px 0px var(--primary-color) !important;
  background: #fff;
  border-radius: 15px;
  text-align: center;
}

.audit-tool-card-interum {
  flex-grow: 1;
  max-width: 600px;
  min-width: 300px;
  margin: 10px;
}

.question-title {
  font-family: var(--body-font);
  font-size: 20px;
  color: var(--secondary-color);
  white-space: pre-wrap;
  overflow-wrap: break-word;
  word-wrap: break-word;
  a {
    text-decoration: underline;
    color: var(--primary-color);
  }
}

.todo-text {
  font-family: var(--body-font);
  font-size: 15px;
  color: var(--primary-color);
  white-space: pre-wrap;
  font-style: italic;
}

.no-top-margin{
  margin-top: 0 !important;
}

.no-bottom-margin{
  margin-bottom: 0 !important;
}

.no-left-margin{
  margin-left: 0 !important;
}

table {
  width: 100%;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell{
  border: none !important;
}

th.mat-header-cell{
  font-family: var(--body-font);
  color: #fff;
  font-size: 17px;
}

.float-right{
  right: -95px;
}

.left-align{
  text-align: left !important;
}

.right-align{
  text-align: right !important;
}

.center-align{
  text-align: center !important;
}

.float-up{
  margin-top: -10px;
}

.padding-top{
  padding-top:10px;
}

.chip-left-icon {
  margin: 0 !important; 
  padding-right: 0.4rem;
}

.link-icon{
  font-size: 25px;
  padding-left: 5px;
  color: var(--secondary-color);
}

.padded-icon{
 margin: 20px 10px;
}

.none-found-message{
  padding: 50px;
}

.med-green{
  color: var(--primary-color);
}

.checkmark-circle .background {
  background: var(--primary-color);
}

.dark-green{
  color: var(--secondary-color);
}

.light-green {
  color: var(--tertiary-color);
}

.light-blue{
  color: #6187C9;
}

.red-back{
  background-color: rgba(201, 76, 56, .3);
}

.light-grey{
  color: rgba(102, 102, 102, .2);
}

.med-grey{
  color: rgba(102, 102, 102, .6);
}

.orange-text{
  font-family: var(--heading-font);
  color: rgb(223, 152, 62);
}

.red-text a{
  color: var(--red-color);

}

.red {
  color: var(--red-color) !important;
}

.red-text {
  font-family: var(--heading-font);
  color: var(--red-color);
}

.red-background {
  background-color: var(--red-color) !important;
  border-color: var(--red-color) !important;
  :hover {
    background-color: rgb(152, 61, 44) !important;
    border-color: rgba(152, 61, 44, 1) !important;
  }
}

.primary-text {
  font-family: var(--heading-font);
  color: var(--primary-color);
}

.subtle-centered-text{
  width: 100%;
  text-align: center;
  color: rgba(102, 102, 102, .4);
  margin-bottom: -5px;
  margin-top: 10px;
  font-family: var(--heading-font);
}

.question-section-title {
  font-family: var(--heading-font);
  font-size: 25px;
}

.survey-submit {
  padding: 5px 20px;
}

.info-icon{
  float: right;
  cursor: pointer;
}

.close-container{
  width: 100%;
}

.semi-disabled{
  background-color: var(--secondary-color);
  pointer-events: none;
}

.dialog-backdrop{
  background-color: rgba(255, 255, 255, .6);
  backdrop-filter: blur(2px);
}

.dialog-backdrop-mobile{
  opacity: 0 !important;
}

.dialog-backdrop-search-items{
  opacity: 0 !important;
}

.action-check-box{
  font-size: 50px;
}

/*admin landing page*/
.list-screen-container{
  align-content: center;
  flex-direction: column;
}

.list-screen-card{
  max-width: 90vw;
  width: 100%;
}

.more-list-screen-card{
  height: auto;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
}

.admin-button-container{
  display: flex;
  justify-content: flex-end;
}

.cdk-overlay-pane{
  max-width: 90vw !important;
}

/*List Pages Styles*/


.survey-line {
  border-bottom: solid 1px rgba(151, 151, 151, 0.35);
  border-top: solid 1px rgba(151, 151, 151, 0.35);
  display: flex;
  padding:0;
  min-height: 50px;
  margin-top: -1px;
  align-items: center;
  background: none;
  &:focus {
    outline: 0;
  }
  &:hover{
    cursor: pointer;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, .3);
  }
  &.no-effect {
    &:hover {
      box-shadow: none;
    }
  }
  &.highlighted{
    background-color: var(--tertiary-opaque) !important;
  }
}

.survey-detail-block {
  padding: 10px;
  min-width: 150px;
  max-width:250px;
  flex-direction: column;
}

.survey-data{
  display: flex;
}

.survey-details-link{
  color: var(--text-color);
  width: 100%;
  outline-color: transparent;
  outline-style: none;
}

.survey-info{
  text-align: left;
  flex-grow: 2;
  min-width: 150px;
}

.question-count{
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: var(--tertiary-color);
  margin: 5px 0;
  font-family: var(--heading-font);
}

.large-count{
  font-size: 25px;
}

.stretch{
  align-items: stretch;
}

.center-vertical{
  align-self: center;
}

.survey-stat {
  text-align: center;
  flex-grow: 1;
  padding: 0 5px;
}

.file-name{
  min-width: 200px;
}

.flex-column{
  flex-direction: column;
}

.survey-button{
  text-align: center;
  flex-grow: 1;
}


.template-stat {
  flex: 1 1 0px;
  h2,h3,h4 {
    margin: 0;
  }
}

.survey-stat h1{
  margin: 0;
}

.survey-button .csa-button-solid {
  margin: 0px;
  font-size: 15px;
  width: 120px;
  padding: 5px 10px;
  cursor: pointer;
  &.large {
    margin: 20px;
    font-size: 18px;
    width: 200px;
    padding: 8px 8px;
    max-width: unset;
  }
}

.survey-line-link:hover{
  cursor: pointer;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, .3);
}

.disabled-link{
  pointer-events: none;
}

.survey-heading-line {
  background: var(--color-interface-background-tertiary, #EBEDED);
  color: var(--interface-text-strong, var(--color-interface-text-strong, #3A474E)) !important;
  border: none;
  .survey-details-link {
    color: #fff;
  }
  &:hover {
    cursor: default;
    box-shadow: none;
  }
  h4 {
    font-family: var(--body-font);
    padding-left: 5px;
  }
}

.app-version {
  position: fixed;
  bottom: 5px;
  left: 5px;
  font-size: 10px;
}

.cancel-link{
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.back-link {
  cursor: pointer;
  width: 100%;
  padding: 0 10px 0 10px;
  text-align: left;
  color: var(--secondary-color);
  font-family: var(--heading-font);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;
}

.hide-on-mobile{
    display: block;
  }

.hide-on-desktop{
  display: none;
}

.neg-5-margin{
  margin-top: -5px;
}

.mat-dialog-content{
  margin: 0 !important;
}

.mat-dialog-container {
  width: 90vw;
  color: var(--text-color);
}

.margin-top{
  margin-top: 20px;
}

.cancel-assessment-button{
  margin: 0 !important;
  font-size: 14px !important;
  width: 150px !important;
  padding: 5px !important;
}

@media only screen and (max-width: 670px){

  p{
    font-size: 13px;
  }

  .mat-dialog-container{
    padding: 10px !important;
  }

  .mat-dialog-content{
    overflow: scroll;
    max-width: 100%;
    margin: 0;
    padding: 0 !important;
  }

  .none-found-message{
    padding: 10px;
  }

  .hide-on-mobile{
    display: none;
  }

  .hide-on-desktop{
    display: block;
  }

}

@media only screen and (max-width: 500px) {
    .survey-button .csa-button-solid {
      width: 100px;

    }
}

@media only screen and (max-width: 400px) {
    .page-container{
      padding-left: 0;
      padding-right: 0;
    }

    .survey-button .csa-button-solid {
      width: 100px;
      font-size: 15px;
      padding: 5px;
    }

    .survey-line{
      padding: 5px;
    }

    h1{
      font-size: 25px;
    }

}

/*-------------Survey Lines Ripple----------------*/

.survey-line:not(.no-ripple) {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, var(--tertiary-color) 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }
  &:active:after {
    transform: scale(0, 0);
    opacity: .3;
    transition: 0s;
  }
}

.actions-heading {
  padding-top: 10px;
  margin-bottom: 0;
  text-align: left;
  color: var(--primary-color);
}

.action-line{
  text-align: left;
  display: flex;
  width: 100%;
  overflow-wrap: anywhere;

}

.action-line div{
  flex: 1 1 0px;
}

/**** Styles for header-bar****/
.header-bar {
  font-family: var(--heading-font);
  color: var(--secondary-color);
  box-shadow: 0 0 2px 1px var(--primary-color);
}

.mat-drawer {
  color: var(--secondary-color);
  font-family: var(--body-font);
}


.positive-border{
  border: 1px solid var(--primary-color);
}

.modified-chip mat-chip {
  height: auto;
}

.filter-chips {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}

.mat-standard-chip {
  min-width: max-content;
}

.centered-fields {
	display: flex;
	flex-flow: wrap;
	width: 100%;
	margin: auto;
	justify-content: space-between;
}

.two-field {
	display: inline-block;
	width: calc((100% - 1*2%)/2) !important;
	@media only screen and (max-width: 500px) {
		width: 100%;
	}
}

.three-field {
	display: inline-block;
	width: calc((100% - 2*2%)/3) !important;
	@media only screen and (max-width: 500px) {
		width: 100%;
	}
}

.four-field {
	display: inline-block;
	width: calc((100% - 3*2%)/4) !important;
	@media only screen and (max-width: 500px) {
		width: 100%;
	}
}

div {
  .multiple-field {
    display: inline-block;
    width: calc((100% - ((var(--numShown) - 1) * 2%)) / var(--numShown)) !important;
    @media only screen and (max-width: 500px) {
      width: 100% !important;
    }
  }
}

td {
  .cell-field {
    width: 100%;
    max-width: calc((100%) / var(--numShown));
    @media only screen and (max-width: 670px) {
      max-width: calc((100%) / var(--numShownMobile));
    }
  } 
}

.overflow-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sticky-footer-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 78vh; /* Ensures the wrapper takes the full viewport height. Update to suit page. */
}

.sticky-footer-content {
  flex: 1; /* This pushes the footer down */
  overflow-y: auto;
  padding-right: 16px;
}